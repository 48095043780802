import React, { useState, useEffect } from 'react';

import { add } from './storage';

import loadComponent from '../Loadable';
import { withCart } from './context';

const ButtonInput = loadComponent('button-input');

export default withCart(function CartButton(props) {
  const { value, product, className, style, primary, secondary, updateTotal } =
    props;
  const [label, setLabel] = useState(value);

  useEffect(() => {
    let timer;
    function revertLabel() {
      timer = setTimeout(() => {
        setLabel(value);
      }, 1000);
    }
    revertLabel();
    return () => {
      clearTimeout(timer);
    };
  }, [label]);

  const addProduct = product => {
    add(product);
    setLabel('Added!');
    updateTotal();
  };

  const buttonStyle = {
    backgroundSize: '18px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '90% 50%',
    ...style,
  };
  return (
    <ButtonInput
      value={label}
      className={className}
      onClick={() => addProduct(product)}
      aria-label={`Add ${product.name} to your basket`}
      style={buttonStyle}
      primary={primary}
      secondary={secondary}
    />
  );
});
